<template>
  <div class="container">
    <el-table class="table" stripe ref="tableAll" :height="contentStyleObj" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" :key="tableKey" :data="list" border fit highlight-current-row @selection-change="handleSelectionChange" @sort-change="sortChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" align="center" width="35" />
      <el-table-column label="编码" align="center" prop="id" width="80" type="index">
      </el-table-column>
      <el-table-column label="公司名称" min-width="220">
        <!-- <template #default="scope">
          <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
          <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
          <span style="cursor: pointer;color:#39b0d2" @click="$goEtax(scope.row.id)">{{ scope.row.name }}</span>
        </template> -->
      </el-table-column>
      <el-table-column label="是否零申报" align="center" width="100">
        <!-- <template #default="scope">
          <span>{{ accountingTypeFifler(scope.row.accountingType) }}</span>
        </template> -->
      </el-table-column>
      <el-table-column label="计提状态" align="center" min-width="140">
        <!-- <template #default="scope">
          <div class="item_icon" v-if="scope.row.tax_status == 1">
            <i class="iconfont icon-duihao"></i>
            <p>{{ toStatus4(scope.row.tax_status) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.tax_status == 2">
            <i class="iconfont icon-duihao"></i>
            <p>{{ toStatus4(scope.row.taxStatus) }}</p>
          </div>
        </template> -->
      </el-table-column>
    
      <el-table-column label="结账状态" align="center" min-width="140">
        <!-- <template #default="scope">
          <div class="item_icon" style="display: contents;" v-if="scope.row.settleStatus == '2'">
            <i class="iconfont icon-duihao"></i>
            <p>{{ settleStatusfifle(scope.row.settleStatus) }}</p>
          </div>
          <div class="item_icon" style="display: contents;" v-else-if="scope.row.settleStatus == '4'">
            <i class="iconfont icon-duihao"></i>
            <p>{{ settleStatusfifle(scope.row.settleStatus) }}</p>
          </div>
          <div class="item_icon" style="display: contents;" v-else>
            <i class="iconfont icon-gantanhao"></i>
            <p>{{ settleStatusfifle(scope.row.settleStatus) }}</p>
          </div>
        </template> -->
      </el-table-column>
      
      <el-table-column label="操作" align="center" width="130" class-name="small-padding fixed-width">
        <!-- <template #default="scope">
          <el-button>采集</el-button>
        </template> -->
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list:[],
      contentStyleObj:{}, //高度变化
      formLabelWidth:'100px',
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(280)
  }
}
</script>

<style lang="scss" scoped>
 
.container .table {
  width: 100%;
  margin: 20px 0;
}
</style>